<template>
    <modal :class="`z-20 lg:max-w-full`">
        <template #opener="{ open }">
            <slot name="opener" :open="open" />
        </template>
        <template #default="{ close }" class="max-w-2xl">
            <div class="flex flex-col justify-center items-center">
                <div v-if="action === 'restart'" class=" text-center max-w-[80%]">
                    <div class="flex items-center justify-center">
                        <div class="p-2 rounded-full bg-gradient-to-b">
                            <svg class="w-16 h-16 fill-cyan-400 animate-spin-slow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path class="fa-secondary" opacity=".4" d="M43.6 288c2 13.3 5.2 26.2 9.5 38.6c2.9-3.5 7.3-5.7 12.3-5.7l105.4 0c14.3 0 21.4 17.2 11.3 27.3l-34.6 34.6c29.4 25.4 67.8 40.7 109.7 40.7c10.5 0 20.9-1 30.9-2.8l0 48.6c13-1.9 25.6-4.9 37.8-8.9c-3-2.9-4.9-7-4.9-11.5l0-105.4c0-14.3 17.2-21.4 27.3-11.3l34.7 34.7c26.3-29.6 42.2-68.7 42.2-111.4c0-10.8-1-21.3-3-31.5l48.7 0c-1.9-12.8-4.9-25.3-8.9-37.3c-2.9 4.1-7.7 6.7-13 6.7l-105.4 0c-14.3 0-21.4-17.2-11.3-27.3l36.3-36.3c-29.6-26.3-68.7-42.2-111.4-42.2c-11.3 0-22.4 1.1-33.1 3.3l0-48.7c-13.2 2-26.1 5.3-38.4 9.6c4.6 2.8 7.8 7.9 7.8 13.7l0 105.4c0 14.3-17.2 21.4-27.3 11.3l-36.2-36.2c-25.4 29.4-40.7 67.8-40.7 109.7c0 11.1 1.1 22 3.1 32.5l-48.7 0z"/><path class="fa-primary" d="M257.1 87.5c-11.3 0-22.4 1.1-33.1 3.3l0-48.7c10.8-1.7 21.9-2.5 33.1-2.5c56 0 107 21.3 145.4 56.2l35.1-35.1c10.1-10.1 27.3-2.9 27.3 11.3l0 105.4c0 8.8-7.2 16-16 16l-105.4 0c-14.3 0-21.4-17.2-11.3-27.3l36.3-36.3c-29.6-26.3-68.7-42.2-111.4-42.2zM129.9 145.9c-25.4 29.4-40.7 67.8-40.7 109.7c0 11.1 1.1 22 3.1 32.5l-48.7 0c-1.6-10.6-2.4-21.4-2.4-32.5c0-55.2 20.7-105.5 54.7-143.7L60.7 76.7C50.6 66.6 57.7 49.4 72 49.4l105.4 0c8.8 0 16 7.2 16 16l0 105.4c0 14.3-17.2 21.4-27.3 11.3l-36.2-36.2zm52.2 202.4l-34.6 34.6c29.4 25.4 67.8 40.7 109.7 40.7c10.5 0 20.9-1 30.9-2.8l0 48.6c-10.1 1.4-20.4 2.2-30.9 2.2c-55.2 0-105.5-20.7-143.7-54.7L76.7 453.6c-10.1 10.1-27.3 2.9-27.3-11.3l0-105.4c0-8.8 7.2-16 16-16l105.4 0c14.3 0 21.4 17.2 11.3 27.3zm166.2-16l34.7 34.7c26.3-29.6 42.2-68.7 42.2-111.4c0-10.8-1-21.3-3-31.5l48.7 0c1.5 10.3 2.3 20.8 2.3 31.5c0 56-21.3 107-56.2 145.4l36.7 36.7c10.1 10.1 2.9 27.3-11.3 27.3l-105.4 0c-8.8 0-16-7.2-16-16l0-105.4c0-14.3 17.2-21.4 27.3-11.3z"/>
                            </svg>
                        </div>
                    </div>
                    <h3 class="text-2xl text-gray-300 font-bold tracking-wide">
                        Restart Server?
                    </h3>
                    <p class="text-sm">
                        You are about to <span class="text-cyan-500 font-medium">Restart</span> your server.
                    </p>
                    <div class="mt-4">
                        <v-button color="info" @click="sendPowerAction().then((close))" spinner>
                            Yes, Restart Server
                        </v-button>
                    </div>
                </div>
                <div v-else-if="action === 'stop'" class=" text-center max-w-[80%]">
                    <div class="flex items-center justify-center">
                        <div class="p-2 rounded-full bg-gradient-to-b">
                            <svg class="w-16 h-16 fill-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path class="fa-secondary" opacity=".4" d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zm160-64c0-17.7 14.3-32 32-32l128 0c17.7 0 32 14.3 32 32l0 128c0 17.7-14.3 32-32 32l-128 0c-17.7 0-32-14.3-32-32l0-128z"/><path class="fa-primary" d="M192 160h128a32 32 0 0 1 32 32v128a32 32 0 0 1 -32 32H192a32 32 0 0 1 -32-32V192a32 32 0 0 1 32-32z"/>
                            </svg>
                        </div>
                    </div>
                    <h3 class="text-2xl text-gray-300 font-bold tracking-wide">
                        Stop Server?
                    </h3>
                    <p class="text-sm">
                        You are about to <span class="text-yellow-500 font-medium">Stop</span> your server.
                    </p>
                    <div class="mt-4">
                        <v-button color="warning" @click="sendPowerAction().then((close))" spinner>
                            Yes, Stop Server
                        </v-button>
                    </div>
                </div>
                <div v-else-if="action === 'kill'" class=" text-center max-w-[80%]">
                    <div class="flex items-center justify-center">
                        <div class="p-2 rounded-full bg-gradient-to-b">
                            <svg class="w-16 h-16 fill-danger-500 hover:rotate-180 transition-all ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path class="fa-secondary" opacity=".4" d="M0 224c0 70.7 37.5 133.8 96 174.9c0 .4 0 .7 0 1.1l0 64c0 26.5 21.5 48 48 48l48 0 0-48c0-8.8 7.2-16 16-16s16 7.2 16 16l0 48 64 0 0-48c0-8.8 7.2-16 16-16s16 7.2 16 16l0 48 48 0c26.5 0 48-21.5 48-48l0-64c0-.4 0-.7 0-1.1c58.5-41.1 96-104.1 96-174.9C512 100.3 397.4 0 256 0S0 100.3 0 224zm224 32A64 64 0 1 1 96 256a64 64 0 1 1 128 0zm192 0a64 64 0 1 1 -128 0 64 64 0 1 1 128 0z"/><path class="fa-primary" d="M160 320a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm192 0a64 64 0 1 0 0-128 64 64 0 1 0 0 128z"/>
                            </svg>
                        </div>
                    </div>
                    <h3 class="text-2xl text-gray-300 font-bold tracking-wide">
                        Force-Stop Server?
                    </h3>
                    <p class="text-sm">
                        You are about to <span class="text-danger-600/80 font-medium">Kill</span> your server. This will simply force it to stop, to continue
                        use the button below.
                    </p>
                    <div class="mt-4">
                        <v-button color="danger" @click="sendPowerAction().then((close))" spinner>
                            Yes, Kill Server
                        </v-button>
                    </div>
                </div>
                <p class="text-xs text-gray-500 mt-1">
                    TIP: You can hold SHIFT to skip this confirmation.
                </p>
            </div>
        </template>
    </modal>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import {triggerDaemonAction} from '~/plugins';
import PrettyFileIcons from "~/components/PrettyFileIcons.vue";
import {PowerActionType} from "~/api/services/daemon";
export default defineComponent({
    components: {PrettyFileIcons},
    props: {
        action: {
            type: String as () => PowerActionType,
            required: true,
        }
    },
    setup(props) {
        return {
            sendPowerAction: async () => {
                return triggerDaemonAction('send-power', props.action);
            }
        };
    }
});
</script>
