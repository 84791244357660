<template>
    <div class="flex items-start bg-primary-900" :class="preference === 0 ? 'px-6 pt-3' : 'px-4 py-2 rounded-l-lg'">
        <div class="overflow-hidden mr-1">
            <p class="line-clamp-1 max-w-lg font-medium text-primary-50">
                {{ server?.name }}
            </p>
            <p v-clipboard class="text-sm text-accent-300">
                {{ server?.primaryAllocation()?.displayName() }}
            </p>
        </div>
        <status-indicator :class="preference === 0 ? 'ml-auto' : 'ml-auto md:ml-8'" :status="status" />
    </div>

    <div class="flex flex-wrap px-6 text-sm" :class="preference === 0 ? 'py-2' : 'py-3 md:min-w-40 md:border md:border-l-0 md:border-primary-800 md:rounded-r-lg'">
        <p class="text-left w-1/2">
            <span v-tippy="'generic.server.cpu'">
                <fa :icon="['fas', 'tachometer-alt']" size="sm" fixed-width />
            </span>
            {{ cpu }}
        </p>
        <p class="w-1/2" :class="preference === 0 ? 'text-left' : 'md:text-right'">
            <span v-tippy="'generic.server.memory'">
                <fa :icon="['fas', 'memory']" size="sm" fixed-width />
            </span>
            {{ memory }}
        </p>
        <p class="text-left w-1/2">
            <span v-tippy="'generic.server.disk'">
                <fa :icon="['fas', 'hdd']" size="sm" fixed-width />
            </span>
            {{ disk }}
        </p>
        <p class="w-1/2" :class="preference === 0 ? 'text-left' : 'md:text-right'">
            <span v-tippy="'generic.server.players'">
                <fa :icon="['fas', 'user']" size="sm" fixed-width />
            </span>
            {{ players }}
        </p>
    </div>

    <div v-if="preference === 0" class="px-6 pb-3">
        <div class="flex items-center justify-center">
            <div class="flex items-center justify-center">

                <v-button
                    permission="control.start"
                    class="px-2 text-sm py-1 border rounded-l shadow-none border-primary-700/50 bg-primary-800 text-success-500 transition-all delay-50"
                    :class="(!connected || !off) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-primary-800/50'"
                    v-tippy="`server.power.start`"
                    @click="sendPowerAction('start')"
                >
                    <fa :icon="['fas', 'power-off']" size="sm" fixed-width />
                </v-button>

                <power-modal
                    action="restart"
                    modalClass="max-w-[80%] md:max-w-[50%] lg:max-w-[26%] relative flex flex-col w-full m-auto"
                    modalContentClass="bg-primary-900 p-3 sm:p-4 md:p-6 rounded shadow-md relative animate-fade-in-down overflow-y-none scrollbar-w-thin"
                >
                    <template #opener="{ open }">
                        <button
                            permission="control.restart"
                            class="px-2 text-sm py-1 border shadow-none border-primary-700/50 bg-primary-800 text-info-500 transition-all delay-50"
                            :class="(!connected || (!on && !starting)) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-primary-800/50'"
                            v-tippy="`server.power.restart`"
                            @click.exact="(!connected || (!on && !starting)) || open()"
                            @click.shift="(!connected || (!on && !starting)) || sendPowerAction('restart')"
                        >
                            <fa :icon="['fas', 'sync']" size="sm" fixed-width />
                        </button>
                    </template>
                </power-modal>

                <power-modal
                    action="stop"
                    modalClass="max-w-[80%] md:max-w-[50%] lg:max-w-[26%] relative flex flex-col w-full m-auto"
                    modalContentClass="bg-primary-900 p-3 sm:p-4 md:p-6 rounded shadow-md relative animate-fade-in-down overflow-y-none scrollbar-w-thin"
                >
                    <template #opener="{ open }">
                        <button
                            permission="control.stop"
                            class="px-2 text-sm py-1 border shadow-none border-primary-700/50 bg-primary-800 text-warning-500 transition-all delay-50"
                            :class="(!connected || (!on && !starting)) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-primary-800/50'"
                            v-tippy="`server.power.stop`"
                            @click.exact="(!connected || (!on && !starting)) || open()"
                            @click.shift="(!connected || (!on && !starting)) || sendPowerAction('stop')"
                        >
                            <fa :icon="['fas', 'stop']" size="sm" fixed-width />
                        </button>
                    </template>
                </power-modal>

                <power-modal
                    action="kill"
                    modalClass="max-w-[80%] md:max-w-[50%] lg:max-w-[26%] relative flex flex-col w-full m-auto"
                    modalContentClass="bg-primary-900 p-3 sm:p-4 md:p-6 rounded shadow-md relative animate-fade-in-down overflow-y-none scrollbar-w-thin"
                >
                    <template #opener="{ open }">
                        <button
                            permission="control.kill"
                            class="px-2 text-sm py-1 border shadow-none border-primary-700/50 rounded-r bg-primary-800 text-danger-500 transition-all delay-50"
                            :class="(!connected || (!on && !starting && !stopping)) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-primary-800/50'"
                            v-tippy="`server.power.kill`"
                            @click.exact="(!connected || (!on && !starting && !stopping)) || open()"
                            @click.shift="(!connected || (!on && !starting && !stopping)) || sendPowerAction('kill')"
                        >
                            <fa :icon="['fas', 'skull']" size="sm" fixed-width />
                        </button>
                    </template>
                </power-modal>

                <button
                    class="px-2 text-sm py-1 border shadow-none border-primary-700/50 rounded bg-primary-800 text-white-500 ml-2 transition-all delay-50 cursor-pointer hover:bg-primary-800/50"
                    v-tippy="`server.console.console_popup`"
                    @click="server?.openConsolePopup()"
                >
                    <fa :icon="['fas', 'terminal']" size="sm" fixed-width />
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { state } from '~/core';
import { bytesToString } from '~/helpers';
import StatusIndicator from '~/views/StatusIndicator.vue';
import { NavBarPosition } from '~/api/models/User';
import {PowerActionType, ServerStatus} from "~/api/services/daemon";
import {triggerDaemonAction} from "~/plugins";
import PowerModal from "./PowerModal.vue";

export default defineComponent({
    components: { StatusIndicator, PowerModal },
    setup() {
        const { t } = useI18n();

        const server = state.models.server;
        const socket = state.server.socket;

        return {
            server,
            preference: computed(() => state.user.data?.preferences?.navbarPosition || NavBarPosition.LEFT),
            status: computed(() => socket.status),
            cpu: computed(() => socket.proc?.cpuUsed ? `${socket.proc.cpuUsed?.toFixed(2)}%` : '--'),
            memory: computed(() => {
                if (socket.proc) {
                    const [value, unit] = bytesToString(socket.proc.memoryUsed);
                    return `${value} ${t(`generic.units.${unit}`)}`;
                }

                return '--';
            }),
            disk: computed(() => {
                if (socket.proc) {
                    const [value, unit] = bytesToString(socket.proc.diskUsed);
                    return `${value} ${t(`generic.units.${unit}`)}`;
                }

                return '--';
            }),
            players: computed(() => socket.query ? socket.query.numplayers ?? socket.query.players.length : '--'),

            connected: computed(() => state.server.socket.connected),
            on: computed(() => state.server.socket.status === ServerStatus.ON),
            off: computed(() => state.server.socket.status === ServerStatus.OFF),
            starting: computed(() => state.server.socket.status === ServerStatus.STARTING),
            stopping: computed(() => state.server.socket.status === ServerStatus.STOPPING),
            sendPowerAction(action: PowerActionType) {
                triggerDaemonAction('send-power', action);
            }
        };
    },
});
</script>
